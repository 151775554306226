import { getToken } from "firebase/messaging";
import { messaging } from "./firebase-service"; // Pastikan ini menunjuk ke konfigurasi Firebase Anda


/**
 * Meminta izin untuk notifikasi dan mendapatkan token FCM.
 * 
 * @returns Promise<string | null> - Token FCM jika berhasil, atau null jika gagal.
 */
export const requestNotificationPermission = async (): Promise<string | null> => {
    try {
      // Meminta izin notifikasi dari pengguna
      const permission: NotificationPermission = await Notification.requestPermission();
  
      if (permission === "granted") {
        console.log("Notification permission granted.");
  
        // Mendapatkan token FCM
        const token: string = await getToken(messaging, {
          vapidKey: "BLDMtRy7sNSyBykH_ZvOCOI3B0CpvU9TbmS9JUGczQ9ARJe2To8yQ9zuM2n_KWiIJtmRgpYbGuJQFDOJt_ITDyE", // Ganti dengan kunci VAPID publik dari Firebase Console
        });
  
        if (token) {
          console.log("FCM Token:", token);
          return token; // Kembalikan token
        } else {
          console.warn("No registration token available. Request permission to generate one.");
          return null;
        }
      } else {
        console.warn("Notification permission not granted.");
        return null;
      }
    } catch (error) {
      console.error("Error requesting notification permission:", error);
      return null;
    }
  };