import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getRemoteConfig } from "firebase/remote-config";
import { getMessaging } from "firebase/messaging";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: "AIzaSyAQqt1jlJaXK5Synrft6RpYu_mu7fI_Dkk",
  authDomain: "presitas-fb6db.firebaseapp.com",
  projectId: "presitas-fb6db",
  storageBucket: "presitas-fb6db.appspot.com",
  messagingSenderId: "472501204349",
  appId: "1:472501204349:web:c17c7298708ae399a58814",
  measurementId: "G-NYHXZZMKGX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore and get a reference to the service
const firestore = getFirestore(app);

// Initialize Remote Config and get a reference to the service
const remoteConfig = getRemoteConfig(app);

// Initialize Firebase Messaging
const messaging = getMessaging(app);

export { firestore, remoteConfig, messaging };
