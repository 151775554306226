
import {
  IonApp,
  IonRouterOutlet,
  isPlatform,
  getPlatforms,
  alertController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { requestNotificationPermission } from "./firebase-notifications";
import { getMessaging, onMessage } from "firebase/messaging";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
  },
  created() {
    if (!isPlatform("pwa")) {
      this.$router.push("/info");
    }
    console.log(getPlatforms());

    // meminta izin notifikasi saat aplikasi pertama kali dijalankan
    requestNotificationPermission()
      .then((token: any) => {
        if (token) {
          console.log("Token berhasil didapatkan:", token);
          // Simpan token ke server jika diperlukan
        } else {
          console.warn("Tidak mendapatkan token.");
        }
      })
      .catch((error: any) => {
        console.error("Gagal meminta izin notifikasi:", error);
      });

    const messaging = getMessaging();

    onMessage(messaging, async (payload) => {
      console.log("Message received. ", payload);

      // Extract notification details
      const title = payload.notification?.title || "Notification";
      const body =
        payload.notification?.body ||
        "This is a notification message from Firebase Cloud Messaging.";
      const image = payload.notification?.image; // Ambil URL gambar jika ada

      // Customize notification with optional image
      const alertOptions = {
        header: title,
        message: body,
        buttons: ["OK"],
        mode: "ios" as const,
      };

      if (image) {
        alertOptions.message = `
      <div>
        <img src="${image}" alt="Notification Image" style="max-width: 100%; margin-bottom: 1em;" />
        ${body}
      </div>
    `;
      }

      // Present the alert
      const alert = await alertController.create(alertOptions);
      await alert.present();
    });
  },
});
